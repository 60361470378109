import React from 'react';
import { deepmerge } from '@mui/utils';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { Theme, ThemeOptions } from '@mui/material/styles';
import lightTheme from 'muiTheme/lightTheme';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    extended: true;
    contained: true;
  }
}

export const fenixThemeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: '#00344a',
          color: 'white',
          fontWeight: '600',
          gap: '12px',
        },
      },
      variants: [
        {
          props: { variant: 'extended' },
          style: {
            width: '232px',
            height: '56px',
            borderRadius: '16px',
            textTransform: 'capitalize',
            boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
            '&:hover': {
              background: '#00344a',
              boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            },
            '&:active': {
              background: '#00344a',
              boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: '100px',
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              background: '#00344a',
              boxShadow: 'none',
            },
            '&:active': {
              background: '#00344a',
              boxShadow: 'none',
            },
          },
        },
      ],
    },
  },
};

const fenixTheme: Theme = deepmerge(lightTheme, fenixThemeOptions);

export const FenixThemeProvider = ({ children }: React.PropsWithChildren) => <ThemeProvider theme={fenixTheme}>{children}</ThemeProvider>;
