import React, { useState, useContext, useEffect } from 'react';
import { SelectionContext } from 'components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { AdvancedSpacingProvider } from './designSettings/AdvancedSpacing/AdvancedSpacingProvider';
import { GridCallbackDetails, GridCellParams, GridColumnHeaderParams } from '@mui/x-data-grid-pro';

export enum TableSettingsTypes {
  TABLE_ROWS = 'TABLE_ROWS',
  TABLE_COLUMNS = 'TABLE_COLUMNS',
}

export type ToggledTableSettingsPanelRows = {
  tableSettingsPanel: TableSettingsTypes.TABLE_ROWS;
  tableApi: {
    tableCallbackDetails: GridCallbackDetails;
    selectedModel: GridCellParams;
  };
};

export type ToggledTableSettingsPanelColumns = {
  tableSettingsPanel: TableSettingsTypes.TABLE_COLUMNS;
  tableApi: {
    tableCallbackDetails: GridCallbackDetails;
    selectedModel: GridColumnHeaderParams;
  };
};

type ToggledTableSettingsPanel = ToggledTableSettingsPanelRows | ToggledTableSettingsPanelColumns;

type SidePanelContextPropsType = {
  setToggledDesignSettingModelType: (modelOpenStatus: GridBlockType | null) => void;
  toggledDesignSettingModelType: GridBlockType | null;
  resetAllPanels: () => void;
  setToggledTableSettingsPanel: (toggledTableSettingsPanel: ToggledTableSettingsPanel | null) => void;
  toggledTableSettingsPanel: ToggledTableSettingsPanel | null;
};

export type SidePanelContextColumnsPropsType = {
  setToggledDesignSettingModelType: (modelOpenStatus: GridBlockType | null) => void;
  toggledDesignSettingModelType: GridBlockType | null;
  resetAllPanels: () => void;
  setToggledTableSettingsPanel: (toggledTableSettingsPanel: ToggledTableSettingsPanelColumns | null) => void;
  toggledTableSettingsPanel: ToggledTableSettingsPanelColumns | null;
};

export type SidePanelContextRowsPropsType = {
  setToggledDesignSettingModelType: (modelOpenStatus: GridBlockType | null) => void;
  toggledDesignSettingModelType: GridBlockType | null;
  resetAllPanels: () => void;
  setToggledTableSettingsPanel: (toggledTableSettingsPanel: ToggledTableSettingsPanelRows | null) => void;
  toggledTableSettingsPanel: ToggledTableSettingsPanelRows | null;
};

export const SidePanelProviderContext = React.createContext<SidePanelContextPropsType>({} as SidePanelContextPropsType);

interface DesignSettingsModelToggleProps {
  children: React.ReactNode;
}
export function SidePanelProvider({ children }: DesignSettingsModelToggleProps) {
  const { selectedBlockIdByIcon, selectedBlockIdByWrapper } = useContext(SelectionContext);

  const [toggledDesignSettingModelType, setToggledDesignSettingModelType] = useState<GridBlockType | null>(null);
  const [toggledTableSettingsPanel, setToggledTableSettingsPanel] = useState<ToggledTableSettingsPanel | null>(null);

  const resetAllPanels = () => {
    setToggledTableSettingsPanel(null);
    setToggledDesignSettingModelType(null);
  };

  useEffect(() => {
    if (!selectedBlockIdByIcon) setToggledDesignSettingModelType(null);
  }, [selectedBlockIdByIcon]);

  useEffect(() => {
    if (!selectedBlockIdByWrapper) setToggledTableSettingsPanel(null);
  }, [selectedBlockIdByWrapper]);

  const contextValue: SidePanelContextPropsType = {
    setToggledDesignSettingModelType,
    toggledDesignSettingModelType,
    toggledTableSettingsPanel: toggledTableSettingsPanel,
    setToggledTableSettingsPanel: setToggledTableSettingsPanel,
    resetAllPanels,
  };

  return (
    <SidePanelProviderContext.Provider value={contextValue}>
      <AdvancedSpacingProvider>{children}</AdvancedSpacingProvider>
    </SidePanelProviderContext.Provider>
  );
}
