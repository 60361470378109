import React, { createContext, useState } from 'react';

export interface BlockInfo {
  blockId: string | null;
  sectionId: string | null;
}

export interface SelectedBlockInfoContextProps {
  setSelectedBlockInfoByIcon: (selectedBlockInfoByIcon: BlockInfo) => void;
  selectedBlockInfoByIcon: BlockInfo;
  setSelectedBlockInfoByWrapper: (selectedBlockInfoByWrapper: BlockInfo) => void;
  selectedBlockInfoByWrapper: BlockInfo;
  selectedBlockIdByIcon: string | null;
  selectedBlockIdByWrapper: string | null;
  selectedSectionId: string | null;
}

export const SelectionContext = createContext<SelectedBlockInfoContextProps>({} as SelectedBlockInfoContextProps);

interface SelectedBlockInfoProviderProps {
  children: React.ReactNode;
}

export const SelectedBlockInfoProvider = ({ children }: SelectedBlockInfoProviderProps) => {
  const [selectedBlockInfoByIcon, setSelectedBlockInfoByIcon] = useState<BlockInfo>({ blockId: null, sectionId: null });
  const [selectedBlockInfoByWrapper, setSelectedBlockInfoByWrapper] = useState<BlockInfo>({ blockId: null, sectionId: null });

  const contextValue: SelectedBlockInfoContextProps = {
    selectedBlockInfoByIcon,
    selectedBlockIdByIcon: selectedBlockInfoByIcon.blockId,
    setSelectedBlockInfoByIcon,
    selectedBlockInfoByWrapper,
    selectedBlockIdByWrapper: selectedBlockInfoByWrapper.blockId,
    setSelectedBlockInfoByWrapper,
    selectedSectionId: selectedBlockInfoByWrapper.sectionId,
  };

  return <SelectionContext.Provider value={contextValue}>{children}</SelectionContext.Provider>;
};
